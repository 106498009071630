.login-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #fff;
  }
  
  .login-page-box {
    max-width: 400px;
    width: 100%;
    padding: 35px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    max-height: calc(100vh - 40px);
    overflow-y: auto;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
  }
  
  .login-page-box::-webkit-scrollbar {
    width: 0px; /* Hide the scrollbar for WebKit browsers */
    background: transparent; /* Make sure the background is transparent */
  }
  
  .login-page-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 275px;
    margin-bottom: 5px;
  }
  
  .login-page-inputGroup1 {
    margin-bottom: 5px;
  }

  .login-page-inputGroup2 {
    margin-bottom: 20px;
  }
  
  .login-page-label {
    display: block;
    margin-bottom: 5px;
  }
  
  .login-page-input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  
  .login-page-checkboxLabel {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .login-page-checkbox {
    margin-bottom: 4px;
    margin-right: 8px;
    transform: scale(1.5); /* Optional: Increase the size of the checkbox for better alignment */
  }
  
  .login-page-button {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: #142d4c;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .login-page-button:hover {
    background-color: #385170;
    transform: scale(1.05);
  }
  
  .login-page-errorMessage {
    margin-top: 20px;
    text-align: center;
    color: #e63946; /* Softer red */
    background-color: rgba(255, 0, 0, 0.05); /* Light red background */
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
    font-style: italic;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  
  .login-page-successMessage {
    margin-top: 20px;
    text-align: center;
    color: green;
  }
  
  .login-page-loaderContainer {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  

.login-page-checkboxContainer {
  display: flex;
  justify-content: space-between; /* Moves checkboxes to opposite sides */
  align-items: center; /* Align vertically in the middle */
  width: 100%; /* Ensure the container takes up the full width */
  margin-top: -10px;
  margin-bottom: 10px;
}

.login-page-checkboxLabel {
  display: flex;
  align-items: center;
}

.login-page-checkbox {
  margin-right: 5px; /* Space between the checkbox and label */
}

/* Target iOS devices specifically */
@supports (-webkit-touch-callout: none) {
  .login-page-checkbox {
    transform: scale(1); /* Reset to default size for iOS */
  }
}