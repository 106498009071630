
html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scrollbars */
  }
/* Page container */
.pending-trans-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; 
    box-sizing: border-box;
    height: 100vh;
    overflow-x: hidden;
  }
  
  /* Navigation bar */
  .pending-trans-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    position: absolute;
    top: 10px;
    left: 20px;
    right: 20px;
    padding-left: 0;
  }
  
  /* Go Back button styling */
  .pending-trans-go-back-button {
    padding: 10px 20px;
    background-color: #142d4c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .pending-trans-go-back-button:hover {
    background-color: #385170;
    transform: scale(1.05);
  }
  
  /* Account dropdown container */
  .pending-trans-accountDropdownContainer {
    position: relative;
    display: inline-block;
    left: -40px;
  }
  
  /* Dropdown button styling */
  .pending-trans-navButtonRight {
    padding: 10px 20px;
    background-color: #142d4c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .pending-trans-navButtonRight:hover {
    background-color: #385170;
    transform: scale(1.05);
  }
  
  /* Dropdown menu styling */
  .pending-trans-dropdown {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #f9f9f9;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    z-index: 10;
    border-radius: 5px;
    width: 115px;
    text-align: center;
    display: none; /* Hidden initially */
  }
  
  /* Show the dropdown on hover */
  .pending-trans-accountDropdownContainer:hover .pending-trans-dropdown {
    display: block;
  }
  
  /* Dropdown button inside menu */
  .pending-trans-dropdownButton {
    background-color: #142d4c;
    color: white;
    padding: 10px 20px;
    width: 100%;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .pending-trans-dropdownButton:hover {
    background-color: #385170;
    transform: scale(1.05);
  }
  
  /* Page title */
  .pending-trans-container h1 {
    margin-top: 60px;
    font-size: 24px;
    color: #142d4c;
    font-weight: bold;
    text-align: center;
  }
  
  
  /* Loading indicator */
  .transactions-loading-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
  }
  
  .loading-message {
    margin-top: 10px;
    font-size: 18px;
    color: #385170;
  }


  .transactions-table-container {
    max-height: 600px; /* Set a max height to trigger vertical scroll */
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: auto; /* Enable horizontal scrolling */
    border: 1px solid #ddd; /* Optional: Add a light border */
  }

  
  .transaction-actions-button-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-end; /* Align items to the right */
    align-items: center; /* Center items vertically */
    gap: 10px;
  }
  

  .trans-submit-drops-button {
    padding: 10px 20px;
    background-color: #142d4c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-top: 7px;
    margin-bottom: -13.5px;
    margin-right: 10px;
    /* Remove margin-top if it's causing issues */
  }
  .trans-view-all-tracked-button:hover,
  .trans-submit-drops-button:hover {
      background-color: #385170; /* Lighter blue on hover */
      transform: scale(1.05); /* Slightly enlarge on hover */
  }

  .trans-view-all-tracked-button {
    padding: 10px 20px;
    background-color: #142d4c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-top: 7px;
    margin-bottom: -13.5px;

    /* Move to the left */
    margin-left: 0; /* Reset margin */
    position: absolute; /* Use absolute positioning */
    left: 10px; /* Align to the left */
    top: 0; /* Adjust top if needed */
}



  .transactions-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    min-width: 100%; /* Ensures horizontal scroll triggers if content exceeds */
  }

  
  .transactions-table th,
  .transactions-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .transactions-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .transactions-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .transactions-table tr:hover {
    background-color: #eaeaea;
  }

  .pending-trans-class-link {
    color: #142d4c;
    cursor: pointer;
    text-decoration: underline;
  }  

  .pending-trans-class-link:hover {
    color: #385170;
    text-decoration: none;
  }

  .flagged-transaction {
    background-color: #fff3cd; /* Light yellow background */
    color: #856404; /* Darker text for contrast */
    cursor: pointer;
    position: relative;
  }
  
  /* Optional: Add a subtle border for better visibility */
  .flagged-transaction::after {
    content: attr(title);
    visibility: hidden;
    opacity: 0;
    
    background-color: #fafafa; /* Soft light grey for readability */
    color: #b71c1c; /* Darker red text for warning */
    text-align: center;
    border-radius: 8px;
    border: 1px solid #b71c1c; /* Dark red border for emphasis */
    padding: 10px 14px;
    position: absolute;
    bottom: 110%; 
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    font-size: 15px;
    font-style: italic;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    z-index: 10;
}

/* Show tooltip on hover */
.flagged-transaction:hover::after {
    visibility: visible;
    opacity: 1;
    transform: translateX(-50%) translateY(-10px);
}


.transaction-actions {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  align-items: center; /* Center buttons horizontally */
  gap: 8px; /* Add space between the buttons */
}

.transaction-removeButton {
  background-color: transparent; /* Transparent background */
  color: #385170; /* Third color */
  border: 2px solid #385170; /* Border matching text color */
  border-radius: 50%; /* Rounded button */
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  width: 36px;
  height: 36px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease, transform 0.2s ease;
}

.transaction-removeButton:hover {
  background-color: #6b7280; /* Light grey background on hover */
  transform: scale(1.1); /* Slightly increase size on hover */
}

.transaction-editButton {
  background-color: transparent; /* Transparent background */
  color: #142d4c; /* Link-style blue color */
  border: none; /* No border */
  padding: 0; /* No padding */
  font-size: 16px; /* Regular text size */
  text-decoration: underline; /* Underline to resemble a link */
  cursor: pointer; /* Pointer cursor for interactivity */
  transition: color 0.2s ease-in-out;
}

.transaction-editButton:hover {
  color: #385170;
  text-decoration: none;
  background-color: transparent;
}



  
  
  /* Responsive styling */
  @media (max-width: 600px) {
    .pending-trans-container {
      padding: 10px;
    }
    
    .transaction-card {
      padding: 10px;
    }

    .transactions-table-container{
      max-width: 400px;
    }
  }
  