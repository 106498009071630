body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.app-background {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-image 1s ease-in-out; /* Smooth transition */
}

.fade {
  opacity: 0;
  transition: opacity 1s ease-in-out; /* Smooth fade transition */
}

.App {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
  width: 300px;
  text-align: center;
}

h1 {
  color: #007bff;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 5px;
  text-align: center !important;
  color: #333;
}

input {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

p {
  color: red;
}

.link {
  margin-top: 15px;
  color: #007bff;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}
