/* General Styles */
body {
    margin: 0;
    font-family: 'Open Sans', Arial, sans-serif;
    background-color: #f5f5f5;
    color: #333;
  }
  
  .aboutus-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  /* Header Styles */
  .aboutus-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 32px;
    background-color: #142d4c;
  }
  
  .aboutus-logo {
    height: 50px;
  }
  
  .aboutus-nav {
    display: flex;
    gap: 16px;
  }
  
  .aboutus-button {
    padding: 8px 16px;
    border: 1px solid #fff;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .aboutus-button-primary {
    background-color: #fff;
    color: #142d4c;
    font-weight: bold;
  }
  
  .aboutus-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .aboutus-button-primary:hover {
    background-color: #e0e0e0;
  }
  
  /* Main Content Styles */
  .aboutus-main {
    flex: 1;
    padding: 32px;
  }
  
  .aboutus-section {
    max-width: 800px;
    margin: 0 auto 64px auto;
    text-align: center;
  }
  
  .aboutus-title {
    font-size: 48px;
    margin-bottom: 16px;
    color: #142d4c;
  }
  
  .aboutus-subtitle {
    font-size: 36px;
    margin-bottom: 16px;
    color: #385170;
  }
  
  .aboutus-text {
    font-size: 18px;
    line-height: 1.6;
    color: #555;
  }
  
  .aboutus-features {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  
  .aboutus-feature {
    background-color: #9fd3c7;
    padding: 24px;
    border-radius: 8px;
    text-align: left;
  }
  
  .aboutus-featureTitle {
    font-size: 28px;
    margin-bottom: 8px;
    color: #142d4c;
  }
  
  .aboutus-imageSection {
    display: flex;
    justify-content: center;
    gap: 16px;
    flex-wrap: wrap;
  }
  
  .aboutus-stockImage {
    max-width: 400px;
    width: 100%;
    border-radius: 8px;
    margin-top: 16px;
  }
  
  /* CTA Section */
  .aboutus-ctaSection {
    text-align: center;
    margin-top: 64px;
  }
  
  .aboutus-ctaSection .aboutus-text {
    margin-bottom: 24px;
  }
  
  /* Footer Styles */
  .aboutus-footer {
    background-color: #142d4c;
    padding: 16px;
    text-align: center;
  }
  
  .aboutus-footerText {
    color: #fff;
    font-size: 14px;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .aboutus-main {
      padding: 16px;
    }
  
    .aboutus-features {
      flex-direction: column;
    }
  }
  
  @media (max-width: 480px) {
    .aboutus-header,
    .aboutus-footer {
      padding: 16px;
    }
  
    .aboutus-title {
      font-size: 36px;
    }
  
    .aboutus-subtitle {
      font-size: 28px;
    }
  
    .aboutus-featureTitle {
      font-size: 24px;
    }
  
    .aboutus-text {
      font-size: 16px;
    }
  }
  