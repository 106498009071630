/* Main profile update container */
.profile-update-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  margin-top: 20px;
  background-color: #f4f4f4;
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
}

/* Navigation bar */
.profile-update-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  position: absolute;
  top: 10px;
  left: 20px;
  right: 20px;
  padding-left: 0;
}

/* Buttons for navigation */
.profile-update-back-button,
.profile-update-logout-button {
  padding: 10px 20px;
  background-color: #142d4c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-update-back-button:hover,
.profile-update-logout-button:hover {
  background-color: #385170;
  transform: scale(1.05);
}

/* Adjust Dropdown Container */
/* Adjust Dropdown Container */
.profile-accountDropdownContainer {
  position: relative;
  display: inline-block;
  margin-right: 30px; /* Increased to shift more to the left */
}

/* Adjust the button styling */
/* Adjust the button styling */
.profile-update-navButtonRight {
  padding: 10px 20px;
  background-color: #142d4c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease; /* Smooth transition */
}

/* Enlarge on click */
.profile-update-navButtonRight:active, .profile-update-navButtonRight:hover {
  transform: scale(1.05); /* Slightly enlarge the button when clicked */
  background-color:#385170;

}
/* Adjust the dropdown to appear directly below the button */
.profile-dropdown {
  position: absolute;
  top: 100%;
  /* add 37% bit */
  
  right: 0; /* Align to the right side of the button */
  background-color: #f9f9f9;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 10;
  border-radius: 5px;
  width: 115px;
  text-align: center;
  display: none;
}

/* Ensure the dropdown remains visible on hover */
.profile-accountDropdownContainer:hover .profile-dropdown {
  display: block;
}

.profile-dropdownButton {
  background-color: #142d4c;
  color: white;
  padding: 10px 20px;
  width: 100%;
  border: none;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.profile-dropdownButton:hover {
  background-color: #385170;
  transform: scale(1.05);
}

/* Profile update title */
.profile-update-title {
  font-size: 35px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  color: #142d4c;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 1.5);
  margin-bottom: 20px;
  text-transform: uppercase;
  background-color: #f9f9f9;
}

/* Form styling */
.profile-update-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-update-form-group {
  margin-bottom: 15px; /* Increased margin for better spacing */
  width: 100%;
}
.profile-update-form-group label {
  margin-bottom: 10px; /* Slightly larger gap between label and input */
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.profile-update-form-group input {
  padding: 12px; /* Slightly increased padding inside input fields */
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px; /* Increased font size for better readability */
}
.profile-update-form-group select {
  padding: 12px; /* Ensure consistent padding inside select fields */
  margin-bottom: 15.5px; /* Slightly increased margin for better spacing  to mathc the input spacing*/
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
}


/* Flexbox container to align input and domain */
.profile-update-username-input-container {
  display: flex;
  align-items: center;
}

.profile-update-username-input-container input {
  flex: 1;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px 0 0 5px; /* Rounded corners only on the left side */
  font-size: 16px;
  box-sizing: border-box;
  width: 70%; /* Shrink the input field */
}

.profile-update-cuny-note {
  color: #555; /* Softer dark grey color */
  font-size: 14px; /* Slightly smaller font size */
  font-style: italic; /* Italicized text */
  margin-top: 5px; /* Small margin at the top */
  margin-bottom: -10px; /* Space below the note */
  text-align: center; /* Center the text */
}

.profile-update-username-domain {
  padding: 12px;
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-left: none; /* Remove border between input and span */
  border-radius: 0 5px 5px 0; /* Rounded corners only on the right side */
  font-size: 16px;
  white-space: nowrap; /* Prevent wrapping */
  position: relative;
  top: -8px; /* Adjust this value to move the domain up or down */
}

.profile-update-button {
  padding: 10px 20px;
  background-color: #142d4c; /* Very dark blue, almost navy or midnight blue */
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: center;
  font-size: 17px;
}

.profile-update-button:hover {
  background-color: #385170; /* Slightly lighter navy blue for hover */
  transform: scale(1.05); /* Slight zoom effect on hover */
}
/* Loading spinner */
.profile-update-loaderContainer {
  margin-top: 10px;
}

/* Success and error messages */
.errorMessage {
  margin-top: 20px;
  text-align: center;
  color: red;
}

.successMessage {
  margin-top: 20px;
  text-align: center;
  color: green;
}

/*  Clear Field buttons */
.clear-button-container {
  text-align: center; /* Centers the button */
  margin-top: -10px;
}

.clear-field-button {
  background: none;
  border: none;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.9em;
  padding: 0;
  transition: color 0.3s ease, font-weight 0.3s ease; /* Add smooth transitions */
}

.clear-field-button:hover {
  background-color: #f9f9f9; /* Light gray background on hover */
  font-weight: bold; /* Slightly emphasize the text */
  text-decoration: none; /* Remove underline for a cleaner hover effect */
}
