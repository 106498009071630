
/*  Page loader: */
.schedule-loading-indicator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height of the viewport */
  background-color: #ececec; /* You can change this to match your theme */
}

.schedule-loading-message {
  margin-top: 10px;
  font-size: 18px; /* Adjust font size */
  font-family: 'Roboto', sans-serif; /* Use your theme's font */
  color: #142d4c; /* Change this to match your theme colors */
}



/* Main page container */
.schedule-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 160vh;
  width: 100vw;
  padding: 20px;
  background-color: #f4f4f4;
  box-sizing: border-box;
}

.schedule-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  position: absolute;
  top: 10px;
  left: 20px;
  right: 20px;
  padding-left: 0;
}

.go-back-button, .logout-button {
  padding: 10px 20px;
  background-color: #142d4c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.go-back-button:hover, .logout-button:hover {
  background-color: #385170;
  transform: scale(1.05);
}

/* Title */
.schedule-page-container h1 {
  margin-bottom: -15px;
  color: #142d4c;
  font-size: 24px;
  font-weight: bold;
}

.schedule-controls {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the whole control section */
  gap: 15px; /* Space between dropdown container and button */
  margin-bottom: 20px; /* Space below the controls section */
}

.schedule-dropdown-container {
  display: flex;
  gap: 10px; /* Space between the labels and dropdowns */
  align-items: center; /* Vertically center the items */
  flex-wrap: wrap; /* Ensure the items don't overflow and stay aligned */
}

/* Styling the labels */
.schedule-dropdown-container label {
  font-size: 14px;
  font-weight: bold;
  color: #142d4c;
  margin-right: 5px;
}

/* Styling the dropdowns */
.schedule-dropdown-container select {
  padding: 8px 10px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  font-size: 14px;
  color: #142d4c;
  background-color: #fff;
  transition: border-color 0.3s, box-shadow 0.3s;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-width: 160px; /* Ensures consistent width */
}

/* Hover and focus effects for dropdowns */
.schedule-dropdown-container select:hover,
.schedule-dropdown-container select:focus {
  border-color: #385170;
  box-shadow: 0 0 8px rgba(56, 81, 112, 0.2);
}

/* Container for the whole form */
.schedule-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center vertically within the container */
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05); /* Soft shadow */
  margin: 20px 0;
  width: 100%; /* Make it responsive */
  margin-left: -15px; /* Center horizontally */
  margin-right: 0px; /* Center horizontally */
  height: 390px;
  flex: 1; /* Flex 1 to take equal space */
  max-width: 500px; /* Control form width */
  min-width: 300px; /* Optional: Set minimum width */
}

/* Styling the form */
.schedule-form {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  gap: 15px;
  width: 100%; /* Ensure form takes full width of container */
  align-items: center; /* Center align the form elements */
}

/* Form group styling */
.form-group {
  display: flex;
  flex-direction: column;
  width: 100%; /* Make form groups full width for consistency */
}
.form-group label {
  font-size: 18px;
  font-weight: bold;
  color: #142d4c;
  margin-bottom: 5px;
}
/* Input and select styling */
.schedule-form select,
.schedule-form input {
  padding: 10px 15px;
  border: 1px solid #ccd1d9;
  border-radius: 8px;
  font-size: 14px;
  color: #142d4c;
  background-color: #f9f9fc;
  outline: none;
  width: 100%; /* Full width within the form group */
  box-sizing: border-box; /* Ensure padding doesn't affect total width */
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

/* Hover and focus effects */
.schedule-form select:hover,
.schedule-form select:focus,
.schedule-form input:hover,
.schedule-form input:focus {
  border-color: #4c6ef5;
  box-shadow: 0 0 6px rgba(76, 110, 245, 0.2);
}

/* Button styling */
.fetch-schedule-button {
  padding: 10px 20px;
  background-color: #9fd3c7;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  width: 100%; /* Full width button to match form layout */
  max-width: 300px; /* Control the max width for the button */
  
}

/* Button hover effect */
.fetch-schedule-button:hover {
  background-color: #8bbfb1;
  transform: scale(1.04);
}

.schedule-search-classes-button {
  padding: 10px 20px;
  background-color: #9fd3c7;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  width: 100%; /* Full width button to match form layout */
  max-width: 300px; /* Control the max width for the button */
  margin-top: -15px;
}

.schedule-search-classes-button:hover {
  background-color: #8bbfb1;
  transform: scale(1.04);
}

/* Note styling */
.schedule-form-note {
  color: #6b7280; /* Subtle gray color for the note */
  font-size: 13px;
  margin-top: 10px;
  text-align: center;
}

.schedule-click-transactions {
  color: #142d4c; /* Navy color for visibility */
  font-size: 13px;
  margin-top: 5px;
  text-align: center;
  background-color: #f0f4f8; /* Light background to blend with the container */
  padding: 5px;
  border-radius: 4px;
  margin-bottom: 0px;
  margin-top: -5px;
}

.schedule-class-search-error {
  color: #d9534f; /* Red color for error */
  font-size: 13px;
  margin-top: 5px;
  text-align: center;
  background-color: #f9d6d5; /* Light red background for visibility */
  padding: 5px;
  border-radius: 4px;
  margin-bottom: 0px;
  margin-top: 10px;
}

/* Main container to align the form and search results side by side */
.schedule-main-container {
  display: flex;
  flex-direction: row; /* Set direction to row to place items side by side */
  gap: 20px; /* Space between the form and search results */
  justify-content: center; /* Center the flex items horizontally */
  align-items: flex-start; /* Align items to the top */
  width: 100%;
  max-width: 1200px; /* Adjust as needed */
  margin: 0 auto; /* Center within the page */
  padding: 10px; /* Optional: Add padding for spacing */
}

/* Form container should occupy part of the space */

/* Search results */
.schedule-search-results-container {
  flex: 1;
  background-color: #f4f4f4; /* Adjusted to match the page background */
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Allows vertical scrolling */
  overflow-x: hidden; /* Prevents horizontal scrolling */
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05); /* Soft shadow */
  margin: 20px 0;
  width: 100%; /* Make it responsive */
  max-width: 500px; /* Control form width */
  min-width: 300px; /* Optional: Set minimum width */
  height: 390px;
}

.schedule-search-results-container h3 {
  text-align: center;
  color: #142d4c; /* Matching the navy color of your design */
  margin-top: -15px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
  background-color: #ffffff; /* Same as container to blend */
  position: sticky; /* Make the title sticky */
  top: -15px; /* Stick to the top of the container */
  z-index: 10; /* Ensure it stays on top of the list items */
  padding: 10px 0; /* Add some padding for visual spacing */
  border-bottom: 1px solid #e0e0e0; /* Optional: Add a subtle bottom border */
}

.schedule-search-results-list {
  list-style: none; /* Remove bullet points */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  width: 100%; /* Ensure it fits within the container */
  height: calc(100% - 50px); /* Adjust height to fit below the sticky header */
  overflow-y: auto; /* Allows vertical scrolling */
  overflow-x: hidden; /* Prevents horizontal scrolling */
  box-sizing: border-box; /* Ensures padding doesn’t add to the total width */
}

/* Each search result item */
.schedule-search-result-item {
  margin-bottom: 10px;
  transition: transform 0.2s;
  
}

/* Styling for the class card */
.schedule-search-class-card {
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #ccd1d9;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.2s;
  position: relative;
  overflow: hidden;
}

.schedule-search-class-card h4 {
  display: flex;
  align-items: center; /* Aligns items vertically centered */
  justify-content: space-between; /* Spreads items evenly */
}

/* Basic hover effects for interaction */
.schedule-search-class-card:hover {
  background-color: #dfe9f3; /* Soft blue to match the design */
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Initial styling for class details to be hidden */
/* Styling for class details */
.schedule-search-class-details {
  display: none; /* Hidden by default */
  font-size: 13px;
  margin-top: 8px;
  background-color: #f0f4f8; /* Light background to blend with the container */
  border-left: 4px solid #4c6ef5; /* Accent border matching your color scheme */
  padding: 8px 12px;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for a sleek look */
  transition: all 0.3s ease; /* Smooth transition effect */
  max-height: 0; /* Hidden by default */
  overflow: hidden; /* Ensures no content spills out */
}
.schedule-search-class-details p {
  margin: 2px;
  color: #385170; /* Navy color for visibility */
}

/* Show details on hover */
.schedule-search-class-card:hover .schedule-search-class-details {
  display: block; /* Make visible on hover */
  max-height: 200px; /* Expand smoothly */
  visibility: visible; /* Make sure it’s visible */
}


/* Styling for the main heading of the class card */
.schedule-search-class-card h4 {
  font-size: 16px;
  margin: 0;
  font-weight: bold;
  color: #142d4c; /* Navy color for visibility */
}



/* Schedule container */
.schedule-container {
  flex-grow: 1;
  width: 100%;
  max-height: 70vh;
  min-height: 650px;
  overflow-y: auto;
  overflow-x: auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


/* Calendar Grid with 1 pixel per minute from 7 AM to 10 PM */
.calendar-grid {
  display: grid;
  grid-template-columns: 100px repeat(7, minmax(115px, 1fr)); /* Time slots + 7 days */
  grid-template-rows: repeat(180, calc(450px / 180)); /* 5-minute blocks instead of 1-minute */
  gap: 5px;
}


/* Empty top-left corner cell */
.empty-cell {
  grid-column: 1;
  grid-row: span 6;
  background-color: transparent;
}

/* Calendar Days (Header for X-axis) */
.calendar-day {
  position: sticky;
  top: -10px;
  background-color: #142d4c;
  color: white;
  text-align: center;
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
  height : 20px;
  z-index: 100; /* Ensure it's above other elements */
}

/* Calendar Time Slots (Y-axis) */
.calendar-time-slot {
  grid-column: 1; /* Time labels in the first column */
  background-color: #142d4c;
  color: white;
  text-align: center;
  font-weight: bold;
  padding: 5px;
  border-radius: 5px;
  grid-row: span 12; /* Each label spans 12 grid rows (1 hour = 12 5-minute blocks) */
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  left: 0;
  z-index: 90; /* Ensure it's above other elements */
}


/* Calendar Cells */
.calendar-cell {
  background-color: #f9f9f9;
  border: 1px solid #d3d3d3;
  min-height: 60px;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
}

/* Class Cards */
.class-card {
  grid-row: span 12; /* Each class card now spans 12 rows per hour */
  background-color: #9fd3c7;
  color: #142d4c;
  border-radius: 8px;
  padding: 10px;
  font-size: 12px;
  border: 1px solid #142d4c;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  position: relative;
}


.class-card:hover {
  background-color: #7bc9ae;
  cursor: pointer;
  transform: scale(1.05);
}

/* For a smooth appearance */
.class-card h4 {
  margin: 5px 0;
  font-size: 16px;
  color:#142d4c;
  font: bold;

}

.class-card p {
  margin: 0;
  font-size: 14px;
  color:#142d4c;
  font: bold;

}



.schedule-navButtonRight {
  margin-right: 40px; /* Adjust this value to move it more to the left */
  position: relative; /* Remove absolute positioning */
  padding: 10px 20px;
  background-color: #142d4c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.schedule-navButtonRight:hover {
  background-color: #385170;
  transform: scale(1.05);
}


.schedule-accountDropdownContainer {
  position: relative;
  display: inline-block;
}

.schedule-dropdown {
  position: absolute;
  top: 100%; /* Places the dropdown directly below the button */
  left: 37%; /* Adjusts the dropdown's horizontal position */
  transform: translateX(-50%); /* Centers the dropdown relative to its width */
  background-color: #f9f9f9;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 10;
  border-radius: 5px;
  width: 115px;
  text-align: center;
  display: none; /* Initially hidden, will be shown on hover */
}

.schedule-accountDropdownContainer:hover .schedule-dropdown {
  display: block;
}

.schedule-dropdownButton {
  background-color: #142d4c;
  color: white;
  padding: 10px 20px;
  width: 100%;
  border: none;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;

}

.schedule-dropdownButton:hover {
  background-color: #385170;
  transform: scale(1.05);
}




/* TESTING BACKEND CONNECTION */

.schedule-loaderContainer {
  margin-top: -5px; /* Small margin above */
  display: flex;
  flex-direction: column; /* Aligns dots and text vertically */
  align-items: center; /* Centers horizontally */
  justify-content: center; /* Centers vertically */
  text-align: center; /* Centers the text */
}

.schedule-loaderMessage {
  color: #001f3f; /* Navy blue to match the loader */
  margin-top: 8px; /* Small space between the loader and message */
  font-size: 14px; /* Adjust the font size for a snug appearance */
  line-height: 1.5;
  padding: 0 10px; /* Add a bit of padding for a snug fit */
}


.schedule-backend-response {
  color: #1e3a5f; /* Subtle dark navy color */
  font-weight: 500; /* Slightly lighter than bold for a refined look */
  /* margin-top: 15px; */
  padding: 10px 15px; /* Adding some padding for better spacing */
  border-left: 4px solid #1c2e4a; /* Adding a subtle border to accentuate the message */
  background-color: #f7f9fc; /* Light background to enhance readability */
  border-radius: 5px; /* Soft rounded corners */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow for a polished appearance */
  font-size: 0.95rem; /* Adjusting font size for a clean and modern feel */
}


.schedule-missing-credentials-warning p {
  color: #142d4c;  /* Set text color to navy */
}

/* Style the clickable link (span) slightly lighter */
.schedule-update-profile-link {
  color: #0000EE;  /* Set text color to grey */
  cursor: pointer;  /* Show pointer cursor on hover */
  text-decoration: none;  /* Remove underline */
}

/* Optional: Add an underline or change style on hover */
.schedule-update-profile-link:hover {
  text-decoration: underline;  /* Add underline on hover */
}

/*  Schedule Popup */
/* Schedule Modal Overlay */
/* Modal Overlay with Background Blur */
.schedule-modal {
  position: fixed;
  inset: 0; /* Equivalent to top: 0; left: 0; width: 100%; height: 100%; */
  background-color: rgba(20, 45, 76, 0.85); /* Slightly dark navy overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it's above other elements */
  backdrop-filter: blur(8px); /* Adds blur effect */
  overflow-y: auto; /* Allow vertical scrolling if needed */
  padding: 10px; /* Add padding to prevent edge clipping */
  box-sizing: border-box;
  height: 100vh; /* Ensure it takes the full screen height */
  scroll-behavior: smooth; /* Ensure smooth scrolling */
}

/* Prevent body from scrolling when the modal is open */
body.modal-open {
  overflow: hidden;
}

/* Schedule Modal Content */
.schedule-modal-content {
  background-color: #f8f9fa; /* Light gray background for a clean look */
  padding: 25px;
  border-radius: 12px;
  width: 90%;
  max-width: 450px;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2); /* Adds depth with shadow */
  color: #142d4c;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  text-align: center;
  animation: fadeIn 0.4s ease;
}

/* Typography adjustments */
.schedule-modal-content h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #142d4c;
}

.schedule-modal-content p {
  color: #333;
  font-size: 14px;
  line-height: 1.4;
  margin: 5px 0;
}

/* Button inside Modal */
.schedule-modal-content button {
  padding: 10px 20px;
  background-color: #385170;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-weight: bold;
}

/* Button hover effect */
.schedule-modal-content button:hover {
  background-color: #142d4c;
  transform: scale(1.05);
}

/* Workframe for adding a class from the add button the modal and submit buttons */ 
.schedule-add-class-button {
  padding: 5px 10px;
  background-color: #9fd3c7; /* Green background */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.schedule-add-class-button:hover {
  background-color: #59ad96; /* Darker green on hover */
  transform: scale(1.05); /* Slightly larger on hover */
}

/* Modal Overlay with Background Blur */
.schedule-add-class-modal {
  position: fixed;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Ensure it stays centered */
  width: 100%; 
  height: 100%; 
  background-color: rgba(20, 45, 76, 0.85); /* Dark navy overlay with opacity */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; 
  backdrop-filter: blur(10px); /* Adds blur effect to the background */
  overflow-y: auto; /* Allow vertical scrolling inside modal if needed */
  overflow-x: hidden;
  padding: 10px; /* Add padding for spacing */
  box-sizing: border-box; /* Include padding in width calculation */
}

/* Modal Form Styling */
.schedule-add-class-modal-form {
  background: #f8f9fa;
  padding: 25px;
  border-radius: 12px;
  width: 90%; 
  max-width: 450px; 
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.3); 
  color: #142d4c; 
  font-size: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px; 
  align-items: center;
  text-align: center;
  animation: fadeIn 0.4s ease; 
  max-height: 90%; 
  overflow-y: auto; 
  scrollbar-width: none; /* For Firefox */
}


.schedule-add-class-modal-form::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit browsers */
}
/* Modal Heading */
.schedule-add-class-modal-form h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #142d4c; /* Navy color */
  margin-top: 0;
  margin-bottom: 5px;
}

/* Modal Text */
.schedule-add-class-modal-form .current-credit-limit {
  background-color: #dfe4ea; /* Slightly darker gray for better contrast */
  color: #142d4c; /* Darker text color */
  padding: 6px 12px; /* Reduced padding for less height */
  border-radius: 6px; /* Slightly smaller rounded corners */
  border: 1px solid #ccd1d9; /* Slightly thinner border */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); /* Reduced shadow size for a more subtle look */
  font-size: 15px; /* Adjusted font size for consistency */
  font-weight: bold;
  margin-bottom: 3px;
  margin-top: 0px;
}

.conflict-info-container{
  background-color: rgba(200, 210, 220, 0.2); /* Light gray with transparency for a very subtle background */
  padding: 10px 15px; /* Add padding for spacing */
  border-radius: 6px; /* Rounded corners for a smooth look */
  font-style: italic; /* Italicize all the text */
  margin-bottom: -15px; /* Adjust as needed */
  color: #555; /* Subtle dark gray for readability */
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center content horizontally */
  justify-content: center; /* Center content vertically */
}


.credit-limit-display {
  background-color: rgba(200, 210, 220, 0.2); /* Light gray with transparency for a very subtle background */
  padding: 6px; /* Add padding for spacing */
  border-radius: 6px; /* Rounded corners for a smooth look */
  font-style: italic; /* Italicize all the text */
  margin: 2px; /* Adjust as needed */
  color: #555; /* Subtle dark gray for readability */
}

.highlighted-number {
  background-color: #9fd3c7; /* Soft blue background */
  color: #333; /* Deep blue text for contrast */
  padding: 4px;
  border-radius: 4px;
  margin-left: 5px;
  font-weight: bold;
}


.schedule-add-class-modal-form p {
  color: #333; /* Subtle dark gray for readability */
  font-size: 14px;
  line-height: 1.4;
  margin: 5px 0;
}

/*  Credit limits formatting */
/* Styles for the checkbox and input fields */
/* Container for the checkbox and label */
.adjust-credit-limit-container {
  display: flex;
  align-items: center; /* Vertically center items */
  justify-content: center; /* Horizontally center within the parent container */
  gap: 8px; /* Space between elements */
  margin: 0 auto; /* Center the container itself if needed */
  max-width: 400px; /* Set a max width to prevent stretching */
}

.adjust-credit-limit-container label {
  color: #333; /* Subtle dark gray for readability */
  font-size: 14px;
  line-height: 20px; /* Match the height of the checkbox */
  margin: 0; /* Remove top and bottom margin for better alignment */
}

.credit-limit-custom-checkbox {
  appearance: none; /* Remove default checkbox styling */
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 2px solid #9fd3c7; /* Light green border */
  background: #fff;
  cursor: pointer;
  margin: 0; /* Remove default margin */
  transition: background-color 0.3s ease, border-color 0.3s ease;
  display: inline-block;
  position: relative;
}


.credit-limit-custom-checkbox:checked {
  background-color: #385170; /* Darker green when checked */
  border-color: #385170;
}

.credit-limit-custom-checkbox:checked::after {
  content: '✓';
  color: #fff;
  font-weight: bold;
  display: block;
  text-align: center;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.credit-limit-input {
  width: 80px; /* Adjust width as needed */
  padding: 8px 6px; /* Adjust padding for a balanced look */
  border-radius: 8px; /* More rounded corners for a smoother look */
  border: 1px solid #9fd3c7; /* Thinner border for a lighter appearance */
  background-color: #fff;
  color: #333; /* Dark text for better readability */
  outline: none;
  font-size: 16px; /* Slightly larger font size */
  font-weight: bold; /* Make the number bold */
  text-align: center; /* Center text within the input */
  transition: border-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease; /* Smooth transition */
  margin: 2px 0; /* Balanced vertical margin */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.credit-limit-input:hover {
  border-color: #82b2a9; /* Change border color slightly on hover */
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15); /* Deeper shadow on hover for a raised effect */
}

.credit-limit-input::placeholder {
  color: #aaa; /* Lighter placeholder text */
}


.add-class-prerequisite-section {
  margin-top: 15px; /* Space above the section */
}

.prerequisite-checkbox-container {
  display: flex;
  align-items: center; /* Vertically centers items */
  gap: 8px;
  justify-content: center;
  margin-top: 10px;
}

/* Style the checkbox */
#prerequisitesCompleted{
  width: 15px;
  height: 15px;
  margin-bottom: 8px;
  /* padding: 0; */
  vertical-align: center;
  cursor: pointer;
  /* Remove any top or vertical-align properties */
}

.no-holds-confirmation {
  display: flex;
  align-items: center; /* Vertically centers items */
  gap: 8px;
  justify-content: center;
  margin-top: 0;
}

#noHoldsCheckbox {
  width: 15px;
  height: 15px;
  margin-bottom: 8px;
  /* padding: 0; */
  vertical-align: center;
  cursor: pointer;
  /* Remove any top or vertical-align properties */
}

/* Styles for the drop-classes-confirmation section */
.drop-classes-confirmation {
  background-color: rgba(200, 210, 220, 0.2); /* Light gray with transparency for a very subtle background */
  padding: 10px 15px; /* Add padding for spacing */
  border-radius: 6px; /* Rounded corners for a smooth look */
  font-style: italic; /* Italicize all the text */
  margin-bottom: 15px; /* Adjust as needed */
  color: #555; /* Subtle dark gray for readability */
}

/* Heading inside the drop-classes-confirmation */
.drop-classes-confirmation h3 {
  text-align: center;
  color: #142d4c; /* Navy color matching your design */
  font-size: 15px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 15px;
}

/* Container for the list of classes */
.drop-classes-list-confirmation {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center align items horizontally */
  gap: 10px; /* Space between items */
  width: 95%; /* Full width */
}

/* Each class item */
.drop-class-confirmation-item {
  background-color: #ffffff; /* White background for contrast */
  border: 1px solid #ccd1d9; /* Subtle border to define the card */
  padding: 10px 15px; /* Spacing inside the card */
  border-radius: 5px; /* Rounded corners */
  width: 100%; /* Full width within the list */
  max-width: 250px; /* Optional: Limit the maximum width */
  text-align: center; /* Center the text */
  margin-left: 50px;
}

/* Styling for the class code and credits */
.drop-class-confirmation-item h4 {
  font-size: 16px;
  color: #142d4c; /* Navy color for consistency */
  margin: 0; /* Remove default margin */
  font-weight: bold;
}

.dropped-classes-checkbox-container{
  display: flex;
  align-items: center; /* Vertically centers items */
  gap: 8px;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: -10px;
}

#ConfirmDroppedClassesCheckbox{
  width: 15px;
  height: 15px;
  margin-bottom: 25px;
  /* padding: 0; */
  vertical-align: center;
  cursor: pointer;
}

.dropped-classes-confirmation
.confirm-add-button,
.cancel-add-button,
.conflicting-drop-button {
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.6);
  font-size: 14px;

}

/* Confirm Button Styling */
.confirm-add-button,
.conflicting-drop-button {
  background-color: #9fd3c7; /* Light green */
  display: flex;
  justify-content: center; /* Centers content horizontally */
  align-items: center;    /* Centers content vertically */
  
}


.confirm-add-button:hover,
.conflicting-drop-button:hover {
  background-color: #59ad96; /* Darker green */
  transform: scale(1.02); /* Slightly larger on hover */
}

/* Cancel Button Styling */
.cancel-add-button {
  background-color: #385170; /* Navy blue */
}

.cancel-add-button:hover {
  background-color: #142d4c; /* Darker navy */
  transform: scale(1.02); /* Slightly larger on hover */
}



/* Drop Classes area within Modal */
.drop-classes-section h3 {
  text-align: center;
  color: #142d4c;
  font-size: 18px;
  font-weight: bold;
  margin-top: 22.5px;
  margin-bottom: 7.5px;
}

.drop-classes-list {
  list-style-type: none; /* Removes bullet points */
  padding: 0; /* Removes padding */
  margin: 0; /* Adjusts margin to zero for alignment */
  width: 100%; /* Ensures it fills the container */
}

.drop-class-item {
  background: #ffffff; /* Light background for visibility */
  border: 1px solid #ccd1d9; /* Subtle border for definition */
  padding: 10px;
  border-radius: 5px; /* Rounded corners for modern feel */
  margin-bottom: 10px; /* Space between items */
  transition: background-color 0.3s ease; /* Smooth background color transition */
}

.drop-class-item:hover {
  background-color: #f0f4f8; /* Lighter on hover for interactive feedback */
}

.drop-class-card {
  display: flex;
  flex-direction: column; /* Stacks information vertically */
  gap: 5px; /* Spacing between elements */
}



.drop-class-summary > div {
  display: flex;
  gap: 220px;
  align-items: center;
  justify-content: flex-end; /* Align buttons to the right */
}

.drop-class-summary h4 {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #142d4c;
}

.drop-class-button {
  padding: 5px 10px;
  font-size: 12px;
  background-color: #9fd3c7;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100px;
  margin-right: 4vw; /* Add space between buttons */
  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.6);
}

.drop-class-button:hover {
  background-color: #59ad96;
}

.drop-class-details-button {
  padding: 5px 10px;
  font-size: 12px;
  width: 100px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #f0f0f0; /* Lighter color for toggle details button */
  color: #333;
}

.drop-class-details-button:hover {
  background-color: #e0e0e0;
}
Th

.drop-class-details {
  display: none; /* Hidden by default */
  font-size: 13px;
  margin-top: 8px;
  background-color: #f0f4f8; /* Light background to blend with the container */
  border-left: 4px solid #4c6ef5; /* Accent border matching your color scheme */
  padding: 8px 12px;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for a sleek look */
  transition: all 0.3s ease; /* Smooth transition effect */
  max-height: 0; /* Hidden by default */
  overflow: hidden; /* Ensures no content spills out */
  /* Move text to left */
  text-align: left;
}

.drop-class-details p {
  margin: 2px;
  color: #385170; /* Navy color for visibility */
  text-align: left;
}

.drop-class-item:hover .drop-class-details {
  display: block; /* Make visible on hover */
  max-height: 200px; /* Expand smoothly */
  visibility: visible; /* Make sure it’s visible */
}


/* Animation for Modal */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 900px) {

  .schedule-controls {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: stretch; /* Ensure items stretch to full width */
    gap: 10px; /* Add space between dropdowns and button */
  }

  .dropdown-container {
    display: flex;
    flex-direction: column; /* Stack labels and dropdowns */
    width: 100%; /* Make the dropdown container full width */
  }

  .dropdown-container select,
  .dropdown-container label {
    width: 100%; /* Ensure both labels and dropdowns take full width */
    margin-bottom: 0px; /* Add space between each label and dropdown */
  }

  .fetch-schedule-button {
    width: 110%; /* Make the button take the full width of its container */
    margin-top: 0; /* Reset margin to keep alignment clean */
    margin-left: -10px;
  }
}

@media (max-width: 800px) {
  .schedule-main-container {
    flex-direction: column; /* Stack the form and search results vertically */
    align-items: center; /* Center items horizontally */
    height: 900px;
  }

  .schedule-search-results-container {
    margin-top: -10px; /* Add some spacing above the search results */
  }
  .schedule-form-container {
    margin-left: 0px;
  }
}

@media (max-width: 600px) {
  .schedule-add-class-modal-form {
    width: 90%; /* Increase the width to take more screen space */
    max-width: 600px; /* Set a larger max-width */
    padding: 20px; /* Add padding to avoid content touching edges */
    box-sizing: border-box; /* Ensure padding is included in width calculation */
  }
  .schedule-add-class-modal {
    align-items: flex-start; /* Align modal towards the top */
    padding-top: 10px; /* Add padding at the top */
  }
  .drop-classes-list{
    max-width: 350px;
  }
  .drop-class-button{

    margin-right: 0px;
  }
  /* Apply scaling to the main container */
  .schedule-page-container {
    transform: scale(0.95); /* Scale down the content slightly */
    transform-origin: top left; /* Anchor the scaling to the top left */
  }

  .schedule-main-container {
    flex-direction: column; /* Stack the form and search results vertically */
    align-items: center; /* Center items horizontally */
    max-width: none; /* Remove max-width constraints */
    padding: 5px; /* Reduce padding for tighter spacing */
  }

  .schedule-form-container,
  .schedule-search-results-container {
    width: 95%; /* Make both containers take almost full width */
    margin: 10px auto; /* Center and add some spacing */
  }
  
  /* Optional adjustments to keep things aligned nicely */
  .schedule-form-container {
    padding: 15px; /* Reduce padding for tighter spacing */
  }

  .schedule-search-results-container {
    margin-top: 5px; /* Adjust margin to keep layout balanced */
  }
}