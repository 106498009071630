.homepage-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #ececec; /* First color for background */
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

.homepage-box {
  max-width: 700px;
  width: 100%;
  padding: 35px;
  border: 1px solid #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
  background-color: #9fd3c7; /* Second color for container background */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  max-height: 90vh;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.homepage-box::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.homepage-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  position: absolute;
  top: 10px;
  left: 20px;
  right: 20px;
  padding-left: 0;
}

.homepage-navButtonLeft, .homepage-navButtonRight {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #142d4c;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.homepage-navButtonLeft:hover, .homepage-navButtonRight:hover {
  background-color: #385170;
  transform: scale(1.05);
}

.homepage-accountDropdownContainer {
  position: relative;
  display: inline-block;
}

.homepage-navButtonRight {
  margin-right: 40px; /* Adjust this value to move it more to the left */
  position: relative; /* Remove absolute positioning */
}

.homepage-dropdown {
  position: absolute;
  top: 100%; /* Places the dropdown directly below the button */
  left: 37%; /* Adjusts the dropdown's horizontal position */
  transform: translateX(-50%); /* Centers the dropdown relative to its width */
  background-color: #f9f9f9;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 10;
  border-radius: 5px;
  width: 115px;
  text-align: center;
  display: none; /* Initially hidden, will be shown on hover */
}

.homepage-accountDropdownContainer:hover .homepage-dropdown {
  display: block;
}

.homepage-dropdownButton {
  background-color: #142d4c;
  color: white;
  padding: 10px 20px;
  width: 100%;
  border: none;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.homepage-dropdownButton:hover {
  background-color: #385170;
  transform: scale(1.05);
}

.homepage-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 225px;
  margin-bottom: 7px;
}

.homepage-inputGroup {
  margin-bottom: 20px;
  width: 100%;
}

.homepage-input {
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.homepage-collegeButton:hover {
  background-color: #0056b3; /* Change background on hover */
  transform: scale(1.05); /* Slight zoom effect */
}

.homepage-collegeButton {
  padding: 10px; /* Adjust padding for better text fit */
  height: 100px; /* Set consistent height */
  width: 100%; /* Make width fill the grid column */
  border-radius: 10px; /* Softer rounded corners */
  border: 1px solid #385170; /* Third color for border */
  background-color: #385170; /* Third color for button background */
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; /* Center text in button */
  box-sizing: border-box; /* Ensure padding and border are included in width and height */
}

.homepage-buttonGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Auto-resize grid columns */
  gap: 15px; /* Space between grid items */
  margin-top: 20px;
  width: 100%;
}

.homepage-footerText {
  margin-top: 20px;
  font-size: 16px;
  color: #555;
}

.homepage-termDropdown {
  position: absolute;
  top: 100%;
  transform: translateX(-50%) translateY(-10px); /* Center align by moving left 50% of its own width */
  width: 90%; /* Adjust width to be within the button */
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  border-radius: 8px;
  padding: 10px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
}
  
.homepage-enrolledCollegeButtonContainer {
  position: relative;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
}

.homepage-enrolledCollegeButton {
  padding: 25px;
  border-radius: 8px;
  border: 1.5px solid #142d4c;
  background-color: #142d4c;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  text-align: center;
  position: relative;
}

.homepage-enrolledCollegeButton:hover {
  transform: scale(1.02);
}

.homepage-enrolledCollegeButton:hover .homepage-enrolledTermDropdown {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.homepage-enrolledTermDropdown {
  position: absolute;
  top: 100%;
  left: 8%;
  transform: translateX(-50%) translateY(-10px);
  width: 80%;
  background-color: #fff  /* White*/;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  z-index: 90;
  border-radius: 8px;
  padding: 10px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
}

.homepage-enrolledTermButton:hover {
  background-color: #142d4c;
  transform: scale(1.05);
}

.homepage-enrolledCollegeButton:hover .homepage-termDropdown,
.homepage-collegeButton:hover .homepage-termDropdown,
.homepage-enrolledCollegeButton:hover .homepage-enrolledTermDropdown {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
  
.homepage-termButton {
  display: block;
  width: 100%;
  padding: 10px 20px;
  color: #fff;
  background-color: #385170;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;

}

.homepage-termButton:hover {
  background-color: #142d4c;
  transform: scale(1.05);
}


@media (max-width: 725px) {
  .homepage-container {
    padding: 0;
    background-color: #9fd3c7;
  }

  .homepage-box {
    max-width: 100%;
    padding: 20px;
    border: none;
    border-radius: 0;
    box-shadow: none;
    background-color: #9fd3c7;
    min-height: 100vh;
  }

  .homepage-nav {
    justify-content: space-between; /* Keeps buttons on opposite sides */
  }

  .homepage-navButtonLeft, .homepage-navButtonRight {
    /* padding: 10px 15px; */
    font-size: 14px;
  }

}
@media (max-width: 355px) {
  .homepage-enrolledCollegeButtonContainer {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
  }
  
  .homepage-enrolledCollegeButton {
    padding: 25px;
    border-radius: 8px;
    border: 1.5px solid #142d4c;
    background-color: #142d4c;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    text-align: center;
    position: relative;
  }
  
  .homepage-enrolledTermDropdown {
    position: absolute;
    top: 100%;
    left: -3px; /* Align with the button */
    width: 95%; /* Match the width of the button */
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    border-radius: 8px;
    padding: 10px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
  }
}
@media (max-width: 100px) {
  .homepage-container {
    padding: 0; /* Remove padding */
    background-color: #9fd3c7; /* Change background to match the green box */
  }

  .homepage-box {
    max-width: 100%; /* Full width */
    padding: 15px; /* Adjust padding */
    border: none; /* Remove border */
    border-radius: 0; /* Remove border radius */
    box-shadow: none; /* Remove shadow */
    background-color: #9fd3c7; /* Ensure the background color matches the green box */
    min-height: 100vh; /* Make sure the box takes the full height of the screen */
  }

  .homepage-nav {
    position: relative; /* Adjust navigation to flow with content */
    top: 0;
    left: 0;
    right: 0;
    justify-content: center; /* Center nav buttons */
  }
  .homepage-navButtonLeft {
    align-self: flex-start; /* Add some spacing from the left edge */
  }

  .homepage-navButtonRight {
    margin-right: 10px; /* Add some spacing from the right edge */
    position: absolute; /* Keep the profile button on the right side */
    right: 10px; /* Adjust the position */
  }
}

