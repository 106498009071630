/* Base styles for all screen sizes */
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-y: scroll; /* Enable vertical scrolling */
}

.classsearch-page-loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height of the viewport */
    background-color: #ececec; /* You can change this to match your theme */
  }
  
  .classsearch-page-loading-container p {
    margin-top: 10px;
    font-size: 18px; /* Adjust font size */
    font-family: 'Roboto', sans-serif; /* Use your theme's font */
    color: #142d4c; /* Change this to match your theme colors */
  }
    

.classsearch-pageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    min-height: 100vh;
    background-color: #ececec; /* First color */
    color: #000; /* Changed to black for contrast */
    overflow: visible; /* Allow overflow to be managed by the body */
}

/* Align search bar and content containers properly */
.classsearch-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    gap: 20px;
}

/* Style for search container and tracked classes container */
.classsearch-searchContainer{
    width: 95%;
    background-color: #9fd3c7; /* Second color */
    color: #000; /* Black text */
    padding: 20px;
    border-radius: 15px;
    border: 2px solid #385170; /* Third color */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
}

.classsearch-trackedClassesContainer {
    position: relative; /* Set relative positioning for the dropdown to align correctly */
    padding-top: 40px; 
    width: 95%;
    background-color: #9fd3c7; /* Second color */
    color: #000; /* Black text */
    padding: 20px;
    border-radius: 15px;
    border: 2px solid #385170; /* Third color */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    min-height: 300px; /* Fixing the space between 300 and px */
    max-height: calc(100vh - 60px); /* Ensures container height is within viewport minus padding/margin */
}
.classsearch-trackedClassesContainer h2 {
    text-align: center;
    font: optional;
    font-size: 40px;
    margin-top: 0px;
}
/* Search Bar Box */
.classsearch-searchBox {
    position: relative; /* Set relative positioning for the dropdown to align correctly */
    box-sizing: border-box;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto 20px auto;
    background-color: #9fd3c7;
    padding: 15px;
    border-radius: 10px;
    border: 2px solid #385170;
    font-size: 16px;
    color: #000;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative; /* Added this */
}
.classsearch-dropdownContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  
.classsearch-dropdownWrapper {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  gap: 10px; /* Add space between the two dropdowns */
  margin-left: 10px;;
}

.classsearch-collegeDropdown,
.classsearch-termDropdown {
  margin: 0;
  padding: 8px;
  font-size: 13px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  width: 150px; /* Adjust the width to your preference */
  text-align: center;
}

.classsearch-collegeDropdown:hover,
.classsearch-termDropdown:hover {
  border-color: #385170;
  box-shadow: 0 0 8px rgba(56, 81, 112, 0.3);
}

  
.classsearch-searchBox input {
    overflow-x: auto;
    flex-grow: 1; /* Allow input to take remaining space */
    max-width: 800px; /* Maximum width */
    padding: 15px;
    border-radius: 10px;
    border: 2px solid #142d4c; /* Fourth color */
    font-size: 18px;
    color: #000;
    overflow-x: hidden; /* Hide overflow */
    white-space: nowrap; /* Prevent text from wrapping */
    text-overflow: ellipsis; /* Show ellipsis for overflow text */
    
}

.classsearch-title {
    font-size: 40px;
    margin: 0;
    text-align: center;
    font-family: 'Roboto', sans-serif; /* Clean, modern font */
    font-weight: 500; /* Slightly bolder font */
    letter-spacing: 1px; /* Add some letter spacing */
    color: #142d4c; /* Fourth color */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 1.5); /* Subtle shadow for depth */
    margin-bottom: 20px;
}

.classsearch-note {
    position: absolute;  /* Position it within the container */
    top: 5px;  /* Slightly below the top edge */
    left: 10px;  /* Slightly to the right of the left edge */
    background-color: rgba(90, 90, 90, 0.8);  /* Dark gray with slight transparency */
    padding: 4px 12px;  /* Space inside the note */
    border-radius: 20px;  /* Rounded corners for a sleek look */
    font-size: 12px;  /* Slightly smaller font */
    color: white;  /* White text for contrast */
    font-weight: 500;  /* Medium weight */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);  /* Subtle shadow for depth */
}

.classsearch-note1 {
    position: absolute;  /* Position it within the container */
    top: 5px;  /* Slightly below the top edge */
    right: 10px;  /* Slightly to the right of the left edge */
    background-color: rgba(90, 90, 90, 0.8);  /* Dark gray with slight transparency */
    padding: 4px 12px;  /* Space inside the note */
    border-radius: 20px;  /* Rounded corners for a sleek look */
    font-size: 12px;  /* Slightly smaller font */
    color: white;  /* White text for contrast */
    font-weight: 500;  /* Medium weight */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);  /* Subtle shadow for depth */
}

.underline-on-hover {
    color: #142d4c;
}
.underline-on-hover:hover {
    text-decoration: none;
}
/* List styling */
.classsearch-classList, 
.classsearch-trackedClassList {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}
/* Class item box styling */ 
.classsearch-classItem{
    background-color: #142d4c; /* First color */
    color: #000; /* Black text */
    padding: 20px;
    border-radius: 10px;
    border: 2px solid #142d4c; /* Fourth color */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    width: 95%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    cursor: pointer;
}
.classsearch-classItem .hover-info {
    display: none; /* Initially hidden */
    position: absolute;
    top: 100%; /* Position it directly below the item */
    left: 0; /* Align to the left of the item */
    width: 95%; /* Adjust width to match the item */
    background-color: #385170; /* Third color for contrast */
    color: #ececec; /* First color */
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    z-index: 3;
    text-align: left;
}

.classsearch-classItem .hover-info div {
    margin-bottom: 5px; /* Add some spacing between each line */
}

/* Show the additional info on hover */
.classsearch-classItem:hover .hover-info {
    display: block;
}
/* Tracked Class Item Styling */
.classsearch-trackedClassItem {
    background-color: #ececec; /* First color */
    color: #000; /* Black text */
    padding: 15px; /* Adjust padding */
    border-radius: 12px; /* More rounded corners */
    border: 2px solid #142d4c; /* Fourth color */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Softer shadow */
    width: 250px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    cursor: pointer;
    text-align: left; /* Align text to the left */
}

.classsearch-trackedClassItem:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
}


/* Updated text alignment and font styling */
.classsearch-trackedClassItem span {
    font-family: 'Roboto', sans-serif; /* Clean, modern font */
    font-weight: 500; /* Medium weight for better readability */
    background-color: #9fd3c7; /* Second color */
    color: #000; /* Black text */
    padding: 4px 8px; /* Increased padding for spacing */
    border-radius: 5px; /* Softer rounded corners */
    margin-bottom: 10px; /* Space between the span and other content */
}
.classsearch-classItem:hover{
    transform: translateY(-3px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}
/* Additional alignment for a cleaner look */
.classsearch-trackedClassItem .classInfo {
    margin-bottom: 15px; /* Space between text and remove button */
    text-align: center; /* Center align text */
}
/* Remove Button Styling */
.classsearch-removeButton {
    background-color: transparent; /* Transparent background */
    color: #385170; /* Third color */
    border: 2px solid #385170; /* Border matching text color */
    border-radius: 50%; /* Rounded button */
    padding: 8px;
    cursor: pointer;
    font-size: 16px;
    width: 36px;
    height: 36px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s ease, transform 0.2s ease;
}

.classsearch-removeButton:hover {
    background-color: #5e6472; /* Light grey background on hover */
}


/* Add Button Styling */
.classsearch-addButton {
    background-color: #385170; /* Third color */
    color: #ececec; /* First color */
    border: none; /* Remove the border */
    border-radius: 50%; /* Round button */
    padding: 8px; /* Adjust padding for better spacing */
    cursor: pointer;
    font-size: 18px; /* Slightly larger font size */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Slightly deeper shadow for emphasis */
    transition: background-color 0.2s ease, transform 0.2s ease, box-shadow 0.2s ease;
    width: 40px; /* Increase button size for better touch target */
    height: 40px; /* Match width and height */
    display: flex;
    justify-content: center;
    align-items: center; /* Center the icon vertically and horizontally */
    margin-left: auto; /* Align the button to the right */
}

.classsearch-addButton:hover {
    background-color: #142d4c; /* Darken on hover */
    transform: scale(1.1); /* Slightly scale-up on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover for depth */
}


.classsearch-classItem span{
    background-color: #9fd3c7; /* Second color */
    color: #000; /* Black text */
    padding: 2px 5px;
    border-radius: 3px;
}
.classsearch-addButton svg {
    font-size: 20px; /* Adjust the icon size */
    color: #ececec; /* Ensure the icon color matches the button text */
}
.classsearch-loadingIndicator {
    display: flex;
    justify-content: center;
    margin-top: 10px; /* Adjust as needed */
    font: 300px;
  }
  
/* Dropdown Content Styling */
.classsearch-dropdownContentsearchbar {
    position: absolute;
    top: calc(100% + 2px); /* Correct alignment with search bar */
    left: 50%;
    transform: translateX(-50%);
    width: 90%; /* Takes up most of the search box width */
    max-width: 650px;
    max-height: 300px;
    background-color: #142d4c;
    border-radius: 10px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
    padding: 10px 20px;
    z-index: 2;
    overflow-y: auto;
}

/* Dropdown List Items Styling */
.classsearch-dropdownContentsearchbar li {
    background: linear-gradient(135deg, #9fd3c7, #ececec); /* Gradient for subtle design */
    color: #000;
    padding: 12px 15px;
    border-radius: 8px;
    border: 2px solid #385170;
    width: 95%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}


.classsearch-dropdownContent {
    background-color: #385170; /* Third color for contrast */
    color: #ececec; /* First color for text */
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Softer shadow */
    position: absolute;
    top: 100%; /* Ensure it appears below the tracked class item */
    left: 0;
    width: 95%; /* Same width as the tracked class item */
    z-index: 3; /* Ensure it appears above other content */
    text-align: left;
}
/* Style individual lines within the expanded info */
.classsearch-dropdownContent div {
    margin-bottom: 5px; /* Add some spacing between each line */
}

/* Dropdown List Items Styling */
.classsearch-dropdownContent li {
    background-color: #9fd3c7; /* Second color */
    color: #000; /* Black text */
    padding: 8px 10px; /* Adjust padding for a more compact look */
    border-radius: 5px; /* Slightly rounder corners */
    border: 2px solid #385170; /* Third color */
    width: 95%;
    box-sizing: border-box;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    cursor: pointer;
    display: flex;
    justify-content: space-between; /* Space between text and button */
    align-items: left; /* Center content vertically */
    min-width: 250px; /* Ensure a minimum width for items */
    text-align: left; /* Align text to the left */
}

.classsearch-dropdownContentsearchbar li:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Hover effect for depth */
}

.classsearch-logo {
    height: 100px; /* Adjust height as needed */
    width: auto; /* Maintain aspect ratio */
    cursor: pointer; /* Indicates the logo is clickable */
    transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth hover effects */
}
.classsearch-logo:hover {
    transform: scale(1.07); /* Slightly enlarges the logo on hover */
}

/* Logout button styling */
.classsearch-logoutButton {
    background-color: #142d4c; /* Fourth color */
    color: #ececec; /* First color */
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-left: 15px;
    margin-top: -15px; /* Adjust vertical alignment */
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    transition: background-color 0.2s ease, transform 0.2s ease;
  }
  
  .classsearch-logoutButton:hover {
    background-color: #385170; /* Second color on hover */
    transform: scale(1.05);
  }
  
  /* Back button styling */
  .classsearch-backButton {
    background-color: #142d4c; /* Fourth color */
    color: #ececec; /* First color */
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-left: 15px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    transition: background-color 0.2s ease, transform 0.2s ease;
  }
  
  .classsearch-backButton:hover {
    background-color: #9fd3c7; /* Second color on hover */
    transform: scale(1.05);
  }
/* Media query for smaller screens */
@media (max-width: 650px) {
    .classsearch-searchBox input {
        width: 95%; /* Full width on small screens */
        max-width: 95%; /* Full width on small screens */
        font-size: 14px; /* Smaller font size for better fit */
        padding: 10px; /* Adjust padding for better spacing */
    }
    .classsearch-container {
        flex-direction: column;
        align-items: center;
        height: auto;
        width: 95%;
    }
    .classsearch-collegeDropdown,
    .classsearch-termDropdown {
        max-width: 60px;
        font-size: 8px;
    }
    .classsearch-searchContainer, 
    .classsearch-trackedClassesContainer {
        width: 95%;
        height: auto;
    }

    .classsearch-classItem {
        width: 100%; /* Ensure the item spans the full width */
        display: flex;
        justify-content: space-between; /* Space between text and add button */
        align-items: center; /* Center content vertically */
    }
    .classsearch-trackedClassItem {
        width: 95%; /* Full width on small screens */
    }

    .classsearch-dropdownContent li {
        text-align: block;
        flex-basis: 70%; /* Full width on small screens */
    }
    .classsearch-logoutButton {
        font-size: 14px;
        padding: 8px 16px;
        
    }
    .classsearch-logo {
        height: 75px; /* Adjust height for smaller screens */
        margin-left: -15px; /*Adjust position for smaller screens */
        /* top: 10px; Adjust position for smaller screens */
       
    }
    .classsearch-accountNavDropdownContainer .classsearch-navDropdownButton {
        left: 0;
        top: -5px;
        font-size: 10px !important; /* Ensure it applies by using !important */
        max-width: 75px !important;
    }
    
    .classsearch-navDropdownContent {
        width: 100px;
        font-size: 10px !important; /* Force the font size */
        max-width: 75px !important;
        text-align: center !important; /* Center the dropdown items */
    }
    
    .classsearch-navDropdownButtonItem {
        padding: 2px 4px;
        font-size: 10px !important;
        max-width: 75px !important;
        text-align: center !important;
        display: flex;           /* Use flexbox for centering */
        align-items: center;     /* Vertically center */
        justify-content: center; /* Horizontally center */
        height: 100%;            /* Ensure it fills the available space */
        box-sizing: border-box;  /* Include padding in size calculations */
    }
    
    .classsearch-note1 {
        left: 10px !important; /* Adjust position for smaller screens */
        right: auto;
        top: 30px;
    }
    .classsearch-title {
        margin-top: 40px !important; /* Adjust position for smaller screens */
    }

}

.classsearch-accountNavDropdownContainer {
    position: relative;
    display: inline-block;
    left: 7.5px; /* Adjust position as needed */
    top: -7.5px; /* Adjust position as needed */
}

@media (max-width: 1150px) {
    .classsearch-accountNavDropdownContainer {
        left: 7.5px;
    }
}
  
/* Adjust the button styling */
.classsearch-navDropdownButton {
    padding: 10px 20px;
    background-color: #142d4c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s ease, transform 0.2s ease;
}

.classsearch-navDropdownButton:hover {
    background-color: #385170;
    transform: scale(1.05);
}

/* Dropdown content styling */
.classsearch-navDropdownContent {
    position: absolute;
    /* top: calc(100% + 5px); Make it appear right below the button */
    left: 50%; /* Adjust dropdown to be centered under the button */
    transform: translateX(-50%); /* Ensure the dropdown is centered */
    background-color: #f9f9f9;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    z-index: 10;
    border-radius: 5px;
    width: 115px;
    text-align: center;
    display: none; /* Initially hidden */
    flex-direction: column; /* Stack the dropdown items vertically */
    background-color: transparent; /* Transparent background */
}

/* Show dropdown on hover */
.classsearch-accountNavDropdownContainer:hover .classsearch-navDropdownContent {
    display: flex;
}

/* Dropdown button items */
.classsearch-navDropdownButtonItem {
    padding: 10px 20px;
    background-color: #142d4c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s ease, transform 0.2s ease;
}
  
/* Hover effect for dropdown items */
.classsearch-navDropdownButtonItem:hover {
    background-color: #385170;
    transform: scale(1.05);
  }
  
  /* Remove the border from the last dropdown item */
  .classsearch-navDropdownButtonItem:last-child {
    border-bottom: none;
  }